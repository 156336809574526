

.blog-banner {
    position: relative;
    img {

    }
    h1 {
    position: absolute;
    top: 36%;
    left: 0;
    right: 0;
    text-align: center;
    align-self: center;
    bottom: 0;
    color: #fff;
    }
    @media (max-width: 575.98px) { 
        h1 {
            top: 25%;
            font-size: 28px;
        }
    }
}

.blog-page {
    margin: 50px 0;
    .clearfix {
        .blog-8 {
            .blogs_content {
                ul {
                    list-style: none;
                    display: flex;
                    padding-left: 0;
                    li {
                        color: #596277;
                        i {

                        }
                    }
                }
            }
            .blog-title {
                a {
                    font-weight: 700;
                    color: #262626;
                    text-decoration: none;
                    &:hover {
                        color: #0a368d;
                    }
                }
            }
            .content {
                p {
                    color: #596277;
                    font-size: 16px;
                    line-height: 28px;
                }
            }
            .blog-quote {
                padding: 31px 80px 31px 40px;
                border-left: 2px solid #0a368d;
                background: #f2f2f2;
                h6 {
                    line-height: 1.6;
                    font-size: 15px;
                    font-weight: 600;
                }
                span {
                    color: #596277;
                    font-size: 14px;
                    i {
                        color: #0a368d
                    }
                }
            }
            .blog-share-area {
                margin-top: 45px;
                .social-tag {
                    .like-section{
                        
                        span{
                            margin-right: 5px;
                            font-size: 14px;
                            font-weight: lighter;
                        }
                        .fa-thumbs-up{
                            font-size: 20px;
                            cursor: pointer;
                            margin-right: 40px;
                            &:hover{
                                color: blue;
                            }
                        }
                        .fa-thumbs-down{
                            font-size: 20px;
                            cursor: pointer;
                            margin-right: 40px;
                            &:hover{
                                color: blue;
                            }
                        }
                    }
                    span {
                        color: #0b2154;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 1;
                    }
                    @media (min-width: 768px) and (max-width: 991.98px) {
                        span {
                            font-size: 14px;
                        }
                    }
                    a {
                        font-size: 15px;
                        line-height: 1;
                        color: #596277;
                        text-decoration: none;
                        &:hover {
                            color: #0b2154;
                        }
                    }
                    @media (min-width: 768px) and (max-width: 991.98px) {
                        a {
                            font-size: 12px;
                        }
                    }
                }
                .footer_social {
                    text-align: right;
                    display: flex;
                    .share-social{
                        button{
                            padding: 0 3px !important;
                        }
                        
                    }
                    span {
                        color: #0b2154;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 1;
                        align-self: center;
                    }
                    .date-view{
                        font-size: 14px;
                        color: grey;
                        padding-right: 10px;
                    }
                    .makeStyles-container-1{
                        color: #000;
                        height: auto;
                        outline: none;
                        position: relative;
                        max-width: 568px;
                        background: white;
                        .makeStyles-iconContainer-3{
                            padding: 0;
                        }
                        .makeStyles-copyContainer-5{
                            display: none;
                        }
                    }
                    @media (min-width: 768px) and (max-width: 991.98px) {
                        span {
                            font-size: 14px;
                        }
                    }
                    a {
                        background: none;
                        color: #596277;
                            i {
                                padding: 0 15px;
                            }
                            i.fa-facebook-f {
                                color: #596277;
                                &:hover {
                                    color: #4267B2;
                                }
                            }
                            i.fa-twitter {
                                color: #596277;
                                &:hover {
                                    color: #1DA1F2;
                                }
                            }
                            i.fa-youtube {
                                color: #596277;
                                &:hover{
                                    color: #FF0000;
                                }
                            }
                            i.fa-linkedin {
                                color: #596277;
                                &:hover {
                                    color: #0077b5;
                                }
                            }
                        @media (min-width: 768px) and (max-width: 991.98px) {
                            i {
                                font-size: 14px;
                                padding: 0 10px;
                            }
                        }
                    }
                }
                @media (max-width: 575.98px) {
                    .footer_social {
                        text-align: left;
                        margin-top: 10px;
                    }
                }
            }
            .gauthors {
                background: #f1f1f1;
                padding: 40px 90px 35px 50px;
                margin-top: 35px;
                .gauthors-media {
                    img {
                        width: 115px;
                        height: 115px;
                        border-radius: 50%;                    
                    }
                    @media (max-width: 575.98px) {
                        .img {
                            border-radius: 50%;
                            display: flex;
                        }
                    }
                    .gauthors-body {
                        h5 {
                            font-weight: 700;
                            margin-bottom: 5px;                        
                        }
                        p{
                            color: #596277;
                            font-size: 15px;
                            line-height: 25px;
                        }
                    }
                }
                @media (max-width: 575.98px) {
                    .gauthors-media {
                        display: unset;
                    }
                }
            }
            @media (min-width: 768px) and (max-width: 991.98px) {
                .gauthors {
                    padding: 40px 25px 35px 25px;
                }
            }
            @media (max-width: 575.98px) {
                .gauthors {
                    padding: 35px 25px;
                }
            }
            .write {
                margin-top: 50px;
                h4 {
                    a {
                        color: #262626;
                        text-decoration: none;
                        font-weight: 700;
                    }
                }
            }
            .comments {
                margin-top: 45px;
                h5 {
                    margin-bottom: 25px;
                    font-weight: 700;
                    font-family: "Barlow Condensed", sans-serif;
                }
                .comment-option {
                    .media {
                        .comment-img {
                            img {
                                width: 80px;
                                height: 80px;
                                border-radius: 50%;
                            }
                        }
                        .media-body {
                            h6 {
                                margin-bottom: 5px;
                                font-family: sans-serif;
                                a {
                                    color: #333;
                                    font-weight: 600;
                                }
                            }
                            span {
                                display: block;
                                font-size: 14px;
                                color: #777;
                            }
                            p {
                                color: #596277;
                                line-height: 25px;
                                font-size: 14px;
                                margin-top: 10px;
                                margin-bottom: 10px;
                            }
                            .reply-comments {
                                color: #0b2154;
                                font-size: 14px;
                                line-height: 1;
                                text-decoration: none;
                                i {
                                    font-size: 12px;
                                    position: relative;
                                    top: 0px;
                                }
                                &:hover {
                                    color: #0a368d;
                                }
                            }
                        }
                    }
                    .see-more {
                        button {
                            font-size: 14px;
                            border: none;
                            background: #2e57a9;
                            color: #fff;
                            padding: 10px 15px;
                            border-radius: 5pc;
                            text-decoration: none;
                            &:hover {
                                background: #0a368d;
                            }
                        }
                    }
                }
            }
            .contact-form-area {
                margin-top: 45px;
                h5 {
                    margin-bottom: 25px;
                    font-weight: 700;
                    font-family: "Barlow Condensed", sans-serif;
                }
                // .like-section{
                //     button{
                //         background: transparent;
                //         color: #2e57a9;
                //         padding: 20px 20px;
                //         &:hover{
                //             color: white;
                //         }
                //     }
                // }
                textarea {
                    background: #f2f2f2;
                    border: 0;
                    border-radius: 0;
                    font-size: 14px;
                }
                button {
                    overflow: hidden;
                    color: #ffffff;
                    background: #2e57a9;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 1;
                    border: 0;
                    font-family: "Barlow Condensed", sans-serif;
                    padding: 21px 55px 21px 35px;
                    display: inline-block;
                    position: relative;
                    z-index: 1;
                    cursor: pointer;
                    text-transform: capitalize;
                    transition: all 0.4s ease-in-out;
                    &:hover {
                        background: #193e88;
                    }
                }
            }
        }
        .blog-4 {
            .blogs-standard-right {
                .widget {
                    margin-bottom: 30px;
                    .widget-search {
                        position: relative;
                        input {
                            color: #0b2154;
                            font-size: 14px;
                            background: #f2f2f2;
                            position: relative;
                            width: 100%;
                            height: 75px;
                            border: 0;
                            padding: 0 40px
                        }
                        input:focus-visible {
                            outline: 0;
                        }
                        @media (min-width: 768px) and (max-width: 991.98px) {
                            input {
                                padding: 0 15px;
                            }
                        }
                        span {
                            color: #193e88;
                            font-size: 16px;
                            position: absolute;
                            right: 40px;
                            top: 50%;
                            transform: translateY(-50%);
                            i {
                                cursor: pointer;
                            }
                        }
                    }
                    .bs-widget-content {
                        border: 2px solid #eaeaea;
                        padding: 45px 30px 30px 30px;
                        .bs-widget-title {
                            font-size: 24px;
                            position: relative;
                            padding-bottom: 20px;
                            margin-bottom: 25px;
                            font-weight: 700;
                            &::before {
                                content: "";
                                position: absolute;
                                background: #193e88;
                                width: 35px;
                                height: 3px;
                                left: 0;
                                bottom: 0;
                                z-index: 1;
                            }
                        }
                        ul {
                            margin: 0px;
                            padding: 0px;
                            li {
                                list-style: none;
                                a {
                                    color: #262626;
                                    font-size: 16px;
                                    font-weight: 500;
                                    display: block;
                                    background: #ffffff;
                                    margin-bottom: 10px;
                                    padding: 2px 0px 2px 0px;
                                    position: relative;
                                    z-index: 1;
                                    transition: 0.3s;
                                    text-decoration: none;
                                    &:hover {
                                        color: #193e88;
                                    }
                                    span {
                                        float: right;
                                        i {
                                            color: #193e88;
                                            font-size: 18px;
                                        }
                                        @media (min-width: 768px) and (max-width: 991.98px) {
                                            i {
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                }
                                @media (min-width: 768px) and (max-width: 991.98px) {
                                    a {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                        .object-flex {
                                img {
                                    width: 100px;
                                    height: 70px;
                                    object-fit: cover;
                                }
                                @media (min-width: 768px) and (max-width: 991.98px) {
                                    img {
                                        width: 70px;
                                        height: 70px;
                                    }
                                }
                                .object-body {
                                h6 {
                                    font-size: 14px;
                                    font-weight: 700;
                                    &:hover {
                                        color: #193e88;
                                    }
                                }   
                                @media (min-width: 768px) and (max-width: 991.98px) {
                                    h6 {
                                        font-size: 10px;
                                    }
                                }
                                p {
                                    margin-bottom: 0;
                                    font-size: 14px;
                                    color: #596277;
                                }
                                @media (min-width: 768px) and (max-width: 991.98px) {
                                    p {
                                        font-size: 9px;
                                    }
                                }
                            }
                        }
                        .cloudtag {
                            p {
                                color: #262626;
                                font-size: 15px;
                                font-weight: 500;
                                line-height: 1;
                                background: #f2f2f2;
                                display: inline-block;
                                padding: 10px 16px;
                                margin: 4px 3px;
                                text-decoration: none;
                                -webkit-transition: all 0.3s ease-out 0s;
                                -moz-transition: all 0.3s ease-out 0s;
                                -ms-transition: all 0.3s ease-out 0s;
                                -o-transition: all 0.3s ease-out 0s;
                                transition: all 0.3s ease-out 0s;
                                &:hover {
                                    color: #ffffff;
                                    background: #193e88;
                                }
                            }
                        }   
                        .instagram {
                            display: flex;
                            flex-wrap: wrap;
                            flex-basis: 0 0 33%;
                            li {
                                flex: 0 0 33%;
                                max-width: 33%;
                                padding: 5px 5px 1px 5px;
                                .insta {
                                    position: relative;
                                    display: block;
                                    margin-bottom: 0;
                                    &::before {
                                        content: "";
                                        position: absolute;
                                        background-image: -moz-linear-gradient(0deg, rgba(216, 19, 36, 0.90196) 0%, rgba(150, 24, 52, 0.9) 32%, rgba(11, 33, 84, 0.9) 99%, #0b2154 100%);
                                        background-image: -webkit-linear-gradient(
                                    0deg
                                    , rgba(216, 19, 36, 0.90196) 0%, rgba(150, 24, 52, 0.9) 32%, rgba(11, 33, 84, 0.9) 99%, #0b2154 100%);
                                        background-image: -ms-linear-gradient(0deg, rgba(216, 19, 36, 0.90196) 0%, rgba(150, 24, 52, 0.9) 32%, rgba(11, 33, 84, 0.9) 99%, #0b2154 100%);
                                        width: 100%;
                                        height: 100%;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        top: 0;
                                        z-index: 1;
                                        -webkit-transition: all 0.3s ease-out 0s;
                                        -moz-transition: all 0.3s ease-out 0s;
                                        -ms-transition: all 0.3s ease-out 0s;
                                        -o-transition: all 0.3s ease-out 0s;
                                        transition: all 0.3s ease-out 0s;
                                        transform: scale(0);
                                    }
                                    img {
                                        width: 100%;
                                    }
                                    span {
                                        position: absolute;
                                        left: 0;
                                        right: 0;
                                        top: 50%;
                                        z-index: 2;
                                        text-align: center;
                                        transform: translateY(-50%) scale(0);
                                        -webkit-transition: all 0.3s ease-out 0s;
                                        -moz-transition: all 0.3s ease-out 0s;
                                        -ms-transition: all 0.3s ease-out 0s;
                                        -o-transition: all 0.3s ease-out 0s;
                                        transition: all 0.3s ease-out 0s;
                                        i {
                                            color: #ffffff;
                                            font-size: 30px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    @media (min-width: 768px) and (max-width: 991.98px) {
                        .bs-widget-content {
                            padding: 25px 15px;
                        }
                    }
                    @media (max-width: 575.98px) {
                        .bs-widget-content {
                            padding: 45px 15px 30px 15px;
                        }
                    }
                }
            }
        }
    }
}
.no-blog-wrap h1 {
    font-size: 30px;
    margin-top: 0px;
    color: #212529;
}
