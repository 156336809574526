$logoColor: #015195;
$whiteColor: #fff;
$navbarColor: #e30613;

.register {
  margin: 50px 0;
  .register-container {
    box-shadow: 0 0px 9px #00000038;
    padding: 50px;
    .register-row {
      .register-img {
        img {
          height: 550px;
          object-fit: cover;
        }
        @media (max-width: 991.98px) {
          img {
            height: 515px;
          }
        }
        @media (max-width: 575.98px) {
          img {
            display: none;
          }
        }
        @media (max-width: 767.98px) {
          img {
            display: none;
          }
        }
        @media (max-width: 575.98px) {
          img {
            display: none;
          }
        }
      }
      .register-6 {
        padding: 0px 40px;
        .heading {
          h2 {
            font-family: sans-serif;
            font-weight: 600;
          }
          @media (max-width: 575.98px) {
            h2 {
              font-size: 26px;
            }
          }
          p {
            color: #8d8c8c;
            font-family: sans-serif;
            padding: 10px 0;
          }
          @media (max-width: 575.98px) {
            p {
              padding: 5px 0;
            }
          }
        }
        .link {
          text-align: center;
          p {
            color: #8d8c8c;
            margin-top: 15px;
            font-family: sans-serif;
          }
        }
        .or {
          text-align: center;
          margin: 25px 0 0px 0;
          p {
            span {
              background: #fff;
              color: #262626;
              z-index: 9999;
              padding: 5px 10px;
              position: relative;
            }
          }
          .hr-line {
            position: relative;
            top: -26px;
          }
        }
        .social-login {
          // margin-top: 25px;
          text-align: center;
          a {
            margin: 0 5px;
            i.fa.fa-facebook-f {
              background: #4267b2;
              color: #fff;
              padding: 10px 14px;
              border-radius: 50%;
            }
            i.fa.fa-google {
              background: #db4437;
              color: #fff;
              padding: 11px 14px;
              border-radius: 50%;
            }
          }
        }
        .form-group {
          padding: 0;
          input {
            color: #444540;
            font-size: 15px;
            height: 45px;
            padding-left: 20px;
            border: 1px solid #666;
            z-index: 9;
            border-radius: 0;
            &:hover {
              border: 1px solid #444540;
            }
          }
          input::placeholder {
            color: #8d8c8c;
            opacity: 1;
          }
          @media (max-width: 991.98px) {
            input {
              height: 35px;
            }
          }
        }
        .form-check {
          padding-left: 0;
          input {
            height: auto;
          }
          label {
            font-size: 14px;
            position: relative;
            top: -3px;
            padding-left: 20px;
          }
        }
        button {
          background: #444540;
          background: #444540;
          color: #fff;
          border: 0;
          height: 45px;
          font-weight: 500;
          margin-top: 15px;
          border-radius: 0;
          border: 1.5px solid #444540;
          &:hover,&:active{
            background: #fff;
            color: #444540;
            border: 1.5px solid #444540;
          }
        }
      }
      @media (max-width: 1199.98px) {
        .register-6 {
          padding: 0px 50px;
        }
      }
      @media (max-width: 991.98px) {
        .register-6 {
          padding: 0px 35px;
        }
      }
      @media (max-width: 575.98px) {
        .register-6 {
          padding: 0px 25px;
        }
      }
    }
  }
  @media (max-width: 575.98px) {
    .register-container {
      padding: 35px 25px;
    }
  }
}
@media (max-width: 575.98px) {
  .register {
    margin: 0;
  }
}
