.modal-content {
    border: 0;
    .header{
        background: red;
    }
    .error-body {
        background: #ff002f46;
        border-radius: 4px;
        .without-login {
            .error-msg {
                p {
                    display: flex;
                    margin-bottom: 0;
                    font-size: 14px;
                    align-self: center;
                    i {
                        font-size: 26px;
                        color: red;
                        margin-right: 10px;
                    }
                    h6 {
                        margin-bottom: 0;
                        align-self: center;
                        position: relative;
                        top: -1px;
                    }
                    @media (max-width: 575.98px) {
                        h6 {
                            position: relative;
                            top: -3px;
                        }
                    }
                    span {
                        align-self: center;
                        padding-left: 5px;
                    }
                }
                @media (max-width: 575.98px) {
                    p {
                        font-size: 12px;
                    }
                }
            }
            
        }
    }
    .success-body {
        background: #84f5845d;
        border-radius: 4px;
        .without-login {
            .success-msg {
                p {
                    display: flex;
                    margin-bottom: 0;
                    font-size: 14px;
                    align-self: center;
                    i {
                        font-size: 26px;
                        color: rgb(116, 185, 25);
                        margin-right: 10px;
                    }
                    h6 {
                        margin-bottom: 0;
                        align-self: center;
                        position: relative;
                        top: -1px;
                    }
                    @media (max-width: 575.98px) {
                        h6 {
                            position: relative;
                            top: -3px;
                        }
                    }
                    span {
                        align-self: center;
                        padding-left: 5px;
                    }
                }
                @media (max-width: 575.98px) {
                    p {
                        font-size: 12px;
                    }
                }
            }
            
        }
    }
    .warning-body {
        background: #f5cc6d54;
        border-radius: 4px;
        .without-login {
            .warning-msg {
                p {
                    display: flex;
                    margin-bottom: 0;
                    font-size: 14px;
                    align-self: center;
                    i {
                        font-size: 26px;
                        color: rgb(255, 196, 0);
                        margin-right: 10px;
                    }
                    h6 {
                        margin-bottom: 0;
                        align-self: center;
                        position: relative;
                        top: -1px;
                    }
                    @media (max-width: 575.98px) {
                        h6 {
                            position: relative;
                            top: -3px;
                        }
                    }
                    span {
                        align-self: center;
                        padding-left: 5px;
                    }
                }
                @media (max-width: 575.98px) {
                    p {
                        font-size: 12px;
                    }
                }
            }
            
        }
    }
}




.footer-btn {
    border: 0 !important;
    .no {
        color: #555;
        background-color: #efefef;
        border-color: #efefef;
    }
    .no:hover {
        color: #555;
        background-color: #d8d4d4;
        border-color: #d8d4d4;
    }
    .yes {
        color: #fff;
        background-color: #e5010e;
        border-color: #e5010e;
    }
    .yes:hover {
        color: #fff;
        background-color: #cc050f;
        border-color: #cc050f;
    }
}


/* -------------------------------- 

File#: _1_alert
Title: Alert
Descr: Feedback message
Usage: codyhouse.co/license

-------------------------------- */

