.banner.header_banner {
  padding: 0;

  .banner.header_banner {
    padding: 0;
  }
  .banner_inner {
    position: relative;
  }
  .banner_img {
    width: 100%;
    margin: 0;
    padding: 0;
    max-width: 100%;
    position: relative;
    img {
      width: 100%;
      max-width: 100%;
      display: flex;
      height: max-content
    }
  }
  .banner_content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    h1 {
      font-size: 65px;
      text-transform: uppercase;
      color: #ef0000;
      font-weight: 600;
      letter-spacing: 2px;
    }
    h2 {
      font-size: 55px;
      font-weight: 300;
      text-transform: uppercase;
      color: #005195;
      letter-spacing: 2px;
    }
  }
  @media (max-width: 767px) {
    .banner_content {
      h1 {
        font-size: 30px;
        letter-spacing: 1px;
      }
      h2 {
        font-size: 25px;
        letter-spacing: 1px;
      }
    }
  }
  @media (max-width: 575px) {
    .banner_content {
      h1 {
        font-size: 22px;
      }
      h2 {
        font-size: 20px;
        letter-spacing: 0;
      }
    }
  }
}

.banner-inner {
  .banner_inner {
    .banner_content h1 {
      color: #ef0000;
    }
  }
}
