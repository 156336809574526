.forget-pass-wrap {
  padding-top: 70px;
  padding-bottom: 70px;
  @media(max-width:768px){
    padding-top: 50px;
    padding-bottom: 50px;
  }
  background-color: #eee;
  .forget-inner {
    max-width: 500px;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: -1px 1px 10px 0px #44454054;
    .logo-wrap {
      img {
        max-width: 160px;
      }
    }
    .forget-pass-form {
      margin-top: 25px;
      .fpf-inner {
        h1 {
          margin: 0;
          padding: 0;
          font-weight: 700;
          margin-bottom: 5px;
          color: #343a40;
          font-size: 30px;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          color: #343a40f2;
        }

        input {
          border: 1px solid grey;
          transition: all 0.15s linear;
          margin-bottom: 0;
          display: block;
          width: 100%;
          padding: 5px;
          min-height: 40px;
          margin-bottom: 15px;
          &:focus {
            outline: none;
            border: 1px solid #4EA699;
            box-shadow: 0px 0px 2px 0px #4ea6991c;
          }
        }
        form {
          margin-top: 20px;
        }
        .error-wrap {
          margin-top: 16px;
          text-align: center;
          p {
            color: red;
          }
        }
        .btn-wrap {
          margin-top: 30px;
          button {
            width: 100%;
            padding: 8px 20px;
            font-size: 14px;
            margin-top: 0;
            font-weight:700;
            letter-spacing: 1PX;
            text-transform: uppercase;
            border-radius: 0px;
            color: #fff;

            transition: background-color 0.3s ease-out;
            background: #444540;
            border: 1.5px solid #444540;
            cursor: pointer;
            &:hover {
              background: #fff;
              color: #444540;
              border: 1.5px solid #444540;
            }
          }
        }
      }
    }
  }
}
