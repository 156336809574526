.header {
  &.active {
    @media (max-width: 767px) {
      position: fixed;
      width: 100%;
      background: #fff;
      z-index: 999;
      height: auto;
      overflow-y: auto; 
    }
  }
  .main_header {
    padding: 10px 0;
    height: 230px;
    background: #000;
    a {
      text-decoration: none;
    }
    .col {
      padding: 0;
      align-items: center;
      display: flex;
      a {
        text-decoration: none;
        color: #000;
      }
    }
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .logo {
      @media (max-width: 767px) {
        width: 60%;
      }
      a {
        img {
          width: 100%;
          max-width: 235px;
          padding:15px;
        }
      }
      span {
        display: block;
        font-size: 13px;
        color: #666666;
        font-weight: 500;
        top: -2px;
        position: relative;
        margin-left: 9px;
        @media (max-width: 991px) {
          display: none;
        }
      }
    }
    .header_search {
      form {
        display: flex;
        align-items: center;
        width: 100%;
      }
    }
    .input {
      position: relative;
      width: 100%;
      span {
        position: absolute;
        top: 1px;
        right: 7px;
        font-size: 23px;
        background: #fff;
        cursor: pointer;
        padding-left: 5px;
      }
    }
    .cart {
      width: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      border: 0;
      position: inherit;
      padding: 0;

      @media (max-width: 767px) {
        width: 26%;
      }
      @media (max-width: 575px) {
        // justify-content: center;
      }
      a {
        color: #000;
        color:#fff;
        text-decoration: none;
        transition: all 0.3s ease;
        margin-left: 5px;
        transition: 0.3s ease-out;
        &:hover {
          transform: none !important;
          transition: 0.3s ease-out;
          // color: #ef0000;

          svg {
            fill: #4EA699;
          }
        }

        &:first-child {
          margin: 0;
        }
        @media (max-width: 767px) {
          margin-left: 8px;
        }
      }
      .icon {
        font-size: 25px;
        vertical-align: middle;
        transition: all 0.3s ease;
        @media (max-width: 767px) {
          padding: 0;
          background-color: transparent;
        }

        svg {
          vertical-align: top;

          @media (max-width: 767px) {
            vertical-align: middle !important;
          }
        }
      }
      .text {
        padding: 0 9px;
        font-size: 12px;
      }
      .mycart {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @media (max-width: 991px) {
          display: none !important;
        }
        span {
          &:last-child {
            width: 100%;
            text-align: center;
            font-size: 12px;
          }
        }
        .icon {
          svg {
            vertical-align: baseline;
          }
        }
      }
    }
  }
  .top_header {
    .col {
      display: flex;
      align-items: center;
      padding: 0;
    }
    background: #444540;
    color: #fff;
    .bg-light {
      background: transparent !important;
      padding: 0;
    }
    .contact_add {
      a {
        color: #fff;
        text-decoration: none;
        span {
          &:first-child {
            padding-right: 10px;
          }
        }
      }
      a.email {
        span {
          &:first-child {
            padding-left: 15px;
          }
        }
      }
    }
    .title {
      justify-content: center;
      h2 {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 700;
        margin: 0;
      }
    }
    .links {
      justify-content: flex-end;
      .pannel {
        background: #444540 ;
        color: white;
        border: none;
      }
      .navbar-nav {
        .nav-link {
          color: #fff;
          padding: 8px;
        }
      }
      .dropdown-menu.show {
        background: #f5f5f5;
        a {
          color: #000;
          text-align: center;
          padding: 4px 15px;
          display: block;
          text-decoration: none;

          &:hover {
            text-decoration: none;
            color: #005195;
          }
        }
      }
    }
  }
  .header {
    .top_header {
      .bg-light {
        a {
          color: #fff !important;
          span {
            padding-left: 5px;
          }
        }
      }
      .nav-item {
        &:last-child {
          .dropdown-menu {
            right: 0;
            left: auto;
          }
        }
      }
    }
  }
  .header_search {
    display: flex;
    align-items: center;
  }
  .search_form {
    select {
      outline: none;
      border: 0;
      padding: 10px;
      // background: #4EA699;
      // background: #444540;
      // color: #fff;
      z-index: 99;
      position: relative;
      right: -1px;
      border: 1px solid #000;
      cursor: pointer;
      @media (max-width: 991px) {
        max-width: 50%;
      }
      option {
        cursor: pointer;
      }
    }
    input {
      padding: 10px 10px;
      border-left: 0;
      border: 1px solid #000;
      border-left: 0;
      width: 100%;
      margin: 0;
      min-height: 39px;
      &:focus {
        border-color: #4EA699;
        outline: none;
      }
    }
  }
  .mycart_inner {
    display: flex;
    flex-direction: column;
    @media (max-width: 991px) {
      position: relative;
    }
  }
  @media (min-width: 768px) {
    .mobile_button {
      display: none !important;
    }
  }
  @media (max-width: 1199px) {
    .header_search {
      max-width: 40%;
    }
  }
  @media (max-width: 991px) {
    .top_header {
      .collapse {
        display: flex;
      }
      .navbar-nav {
        flex-direction: row;
        .dropdown-menu {
          position: absolute;
        }
      }
      .nav-item {
        &:not(:last-child) {
          padding-right: 15px;
        }
      }
      .contact_add {
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;
        align-items: flex-start;
        a {
          span {
            padding-left: 0 !important;
          }
        }
      }
    }
    .main_header {
      .row {
        flex-wrap: nowrap;
      }
      .cart {
        .text {
          padding: 0;
          padding-left: 5px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .header {
      &.active {
        .mobile_button {
          .menu_close {
            display: block;
          }
          .menu_open {
            display: none;
          }
        }
      }
    }
    .top_header {
      display: none;
    }
    .header_search {
      display: block;
      order: 3;
      width: 100%;
      max-width: 100%;
      padding: 0;
      padding-top: 15px;
    }
    .main_header {
      .row {
        @media (max-width: 767px) {
          flex-wrap: wrap;
        }
      }
      .cart {
        a {
          .text {
            display: none;
          }
          .mycart_inner {
            display: none;
          }
        }
      }
      .mobile_button {
        justify-content: flex-end;
        padding-left: 0;
        flex-grow: 0;
        button {
          font-size: 20px;
          background: #4EA699;
          border: 0;
          line-height: 1;
          padding: 5px 10px;
          border-radius: 4px;
          color: #fff;
        }
      }
    }
    .mobile_button {
      .menu_close {
        display: none;
      }
    }
  }
  @media (max-width: 575px) {
    .main_header {
      padding: 5px 10px;
    }
  }
}

.header_wrapper {
  @media (max-width: 767px) {
    min-height: 140px;
  }
  @media (max-width: 417px) {
    min-height: 101px;
  }
}

//////////////////// Mobile Menu //////////////////////////

@media (min-width: 768px) {
  .mobile_menu {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .mobile_menu {
    position: fixed !important;
    width: 100% !important;
    z-index: 99;
    top: 0;
    background: #f1f1f1;
    right: 0;
    left: 0;
    height: calc(100vh - 54px);
    flex-direction: column;
    left: -100%;
    transition: all 0.7s ease;
    max-width: 320px !important;
    overflow-y: auto;

    .mobile_menu_bar {
      display: flex;
      padding: 15px;
      justify-content: space-between;
      width: 100%;
      background: #0a368d;
      color: #fff;

      h5 {
        font-size: 16px;
      }
      button {
        background-color: transparent;
        border: 0;
        color: #fff;

        svg {
          height: 24px;
          width: 24px;
          font-weight: 700;
        }
      }
    }
    ul {
      width: 100%;
      padding: 15px;
      li {
        margin-bottom: 15px;
        font-size: 16px;
        border-bottom: 1px solid #aaa;
        a {
          display: block;
          padding-bottom: 4px;
        }
      }
    }
  }
  .header.active {
    .mobile_menu {
      left: 0;
      transition: all 0.7s ease;
    }
  }
}

/////////////////////// fixed Footer //////////////////

@media (min-width: 992px) {
  .fixed_footer {
    display: none;
  }
}
@media (max-width: 991px) {
  .fixed_footer {
    background: #0a368d;
    background: #444540;
    position: fixed !important;
    bottom: 0;
    z-index: 1000;
    display: block;
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 10px 0;

      li {
        padding: 0 15px;
        &:last-child {
          padding-right: 0;
        }

        a {
          color: #fff;
          font-size: 12px;
          text-decoration: none;
          &:hover{
            color: #4EA699;
          }

          @media (max-width: 991px) {
            font-size: 14px;
          }
          span {
            display: block;
            text-align: center;
            &.close_icon {
              display: none;
            }
          }
        }
      }
    }
  }
  .mycart_inner {
    .text {
      position: absolute;
      font-size: 10px;
      padding: 2px 6px;
      background: #4EA699;
      border-radius: 50%;
      color: #fff;
      top: -21px;
      right: -16px;
    }
  }
}

.header .main_header .cart {
  position: relative;

  .wishlist {
    @media (max-width: 991px) {
      display: none;
    }
  }
}

.shopingCart.hide {
  opacity: 0;
  visibility: hidden;
}

.shopingCart.show {
  opacity: 1;
  visibility: visible;
}

.shopingCart {
  min-width: 400px;
  left: auto;
  right: 0;
  position: absolute;
  width: 100%;
  top: 51px;
  z-index: 99;
  padding: 20px 20px;
  background-color: #fff;
  box-shadow: 1px 0px 7px 1px #6767672b;
  transition: all 0.2s linear;

  @media (max-width: 575px) {
    position: fixed;
    transform: translatey(-50%);
    width: 100%;
    margin: 0;
    top: 50%;
    left: 0;
    right: 0;
  }
}

.shopingCart .title h1 {
  color: #000;
  font-size: 17px;
}

.shopingCart .title {
  margin-bottom: 9px;
  border-bottom: 1px solid #dee2e6;
  position: relative;
}
.shopingCart .title .cross-icon {
  position: absolute;
  right: -5px;
  top: -10px;
  cursor: pointer;
  opacity: 0.4;
  font-size: 12px;
  transition: opacity 0.35s linear, transform 0.25s linear;
}

.shopingCart .title .cross-icon:hover {
  opacity: 1;
  transform: scale(1.1);
}
.header .shopingCart .btn-wrap a.button {
  display: inline-block;
  width: auto;
  text-align: center;
  padding: 8px 20px;
  color: #fff !important;
  border-radius: 3px;
}

.shopingCart .btn-wrap {
  text-align: center;
  margin-top: 25px;
}
.header .shopingCart .btn-wrap a.button {
  display: inline-block;
  width: auto;
  text-align: center;
  padding: 5px 20px;
  color: #fff !important;
  border-radius: 3px;
  font-size: 15px;
  margin-top: 0;
}

.shopingCart .btn-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
}

.shopingCart .list-group-item {
  padding: 0;
}

.shopingCart .si-wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 12px;
}

.shopingCart .si-image {
  width: 30%;
  padding-right: 23px;
}

.shopingCart .si-image img {
  max-width: 136px;
  width: 100%;
  height: auto;
}

.shopingCart .si-details {
  width: 70%;
  position: relative;
}

.shopingCart .list-group-item:not(:first-child) {
  margin-top: 10px;
}

.shopingCart .si-details h6 {
  font-size: 14px;
  margin-bottom: 5px;
  line-height: 1;

  @media (max-width: 575px) {
    line-height: 1.3;
  }
}

.shopingCart .si-details p {
  font-size: 12px;
  color: #3d77f4;
  margin-bottom: 8px;
  line-height: 1;
}

.shopingCart .si-qty-count span {
  display: inline-block;
  padding: 2px 6px;
  border: 1px solid #e4e5e5;
  cursor: pointer;
  font-weight: 700;
  color: #005195;
  font-size: 13px;
  background-color: #f3f4f6;
  transition: background-color 0.15s ease-in-out;
}

.shopingCart .si-qty-count span.count {
  cursor: text;
}

.shopingCart .si-qty-count span:hover {
  background-color: #aaaaaaa6;
}

.shopingCart .si-qty-count span.count:hover {
  background-color: #f3f4f6;
}

.shopingCart .si-details p svg {
  font-size: 14px;
  font-weight: 700;
}

.shopingCart .si-details p span {
  font-size: 10px;
  margin-left: 5px;
}

.shopingCart span.itemDelete {
  position: absolute;
  top: 0;
  right: 13px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
  opacity: 0.5;
  color: red;
  transition: all 0.2s linear;
}

.shopingCart span.itemDelete:hover {
  color: red;
  transform: scale(1.2);
}

.shopingCart .main-content {
  overflow-y: scroll;
  max-height: 362px;
}

.shopingCart .main-content:hover::-webkit-scrollbar-thumb {
  background: linear-gradient(#0051955c, transparent);
}
.main-content::-webkit-scrollbar {
  width: 4px;
}

.shopingCart .main-content::-webkit-scrollbar-thumb {
  background: linear-gradient(transparent, transparent);
}
.shopingCart .btn-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
}

.shopingCart .grand-total-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.shopingCart .grand-total-wrap span {
  display: inline-block;
  width: 100%;
  text-align: left;
  color: #00448d;
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
}

.shopingCart .grand-total-wrap span.grand-total {
  font-size: 20px;
}
.shopingCart .empty-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 57px;
  padding: 6px;
  flex-wrap: wrap;
}
.shopingCart .empty-cart span {
  display: block;
  width: 100%;
  text-align: center;
  color: #4897eb38;
}

.shopingCart .empty-cart p {
  width: 100%;
  font-size: 25px;
  letter-spacing: 2px;
  text-align: center;
  color: #4897eb4a;
  font-weight: 600;
}
.shopingCart .si-image a {
  margin: auto !important;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 460px) {
  .shopingCart {
    min-width: 350px;
    margin-right: -35px;
  }

  .shopingCart .btn-wrap {
    padding-left: 0;
    padding-right: 0;
  }

  .shopingCart .grand-total-wrap {
    width: 50%;
  }
}

@media (max-width: 380px) {
  .shopingCart {
    min-width: 300px;
    margin-right: -30px;
  }
}

@media (max-width: 991px) {
  .active_cat {
    .close_icon {
      display: block !important;
    }
    .open_icon {
      display: none !important;
    }
  }
}

.shopingCart .si-details p.normal-price {
  margin-bottom: 5px;
}

.card_overlay {
  display: none;
}


@media (min-width: 576px) and (max-width: 991px) {
  .shopingCart {
    width: 100%;
    position: fixed;
    z-index: 9999;
    top: -20px;
    .card_overlay {
      display: block;
      background: #000;
      opacity: 0.5;
      z-index: 99;
      width: 100%;
      position: absolute;
      height: 100vh;
      left: 0;
      right: 0;
    }
    .shopingCart_inner {
      position: fixed;
      transform: translateY(17%);
      z-index: 999;
      width: 100%;
      right: 0;
      left: 0;
      background: #fff;
      padding: 20px;
    }
  }
}


@media (max-width: 575px) {
  .shopingCart {
    padding: 0;
    border: 0;
    margin: 0;
    min-width: auto;
    .card_overlay {
      display: block;
      position: absolute;
      height: 100vh;
      width: 100vh;
      background: #000;
      top: -100px;
      right: 0;
      z-index: 1;
      opacity: 0.5;
    }
    .shopingCart_inner {
      background: #ffff;
      z-index: 999;
      opacity: 1;
      position: relative;
      padding: 20px;
      box-shadow: 1px 0px 7px 1px #6767672b;
    }
  }
  .shopingCart.show{
    transform: translateY(-56%);
  }
}




.header .main_header .cart .icon {
  position: relative;
}



.header .main_header .cart .icon .item-number {
  position: absolute;
  right: -13px;
  font-size: 12px;
  top: -6px;
  width: 20px;
  font-weight: 700;
  text-align: center;
  height: 20px;
  // background-color: #fff;
  border-radius: 50px;
}

span.item-number {
}

p.red-alert-text {
  color: #b9b1b1 !important;
  margin-top: 3px;
  font-style: italic;
  font-size: 10px!important;
}