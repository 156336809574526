
.blog-banner {
    position: relative;
    img {

    }
    h1 {
    position: absolute;
    top: 36%;
    left: 0;
    right: 0;
    text-align: center;
    align-self: center;
    bottom: 0;
    color: #fff;
    }
    @media (max-width: 575.98px) { 
        h1 {
            top: 25%;
            font-size: 28px;
        }
    }
}

.blog {
    padding: 50px 0;
    background: #fafafa;
    .blog-container {
        .blog-row {
            .blog-8 {
                article {
                    .blog-card {
                        border: 0;
                        border-radius: 10px;
                        box-shadow: 0px 1px 6px #0003;
                        
                        img {
                            width: 100%;
                            height: 260px;
                            object-fit: cover;
                            border-top-left-radius: 10px;
                            border-bottom-left-radius: 10px;
                        }
                        @media (min-width: 768px) and (max-width: 991.98px) {
                            img {
                                height: 200px;
                            }
                        }
                        .blog-content{
                            flex-direction: column;
                            display: flex;
                            .card-body {
                                .card-title {
                                    font-weight: 800;
                                    font-size: 16px;
                                    display: inline-block;
                                    line-height: 1;
                                   // padding: 5px 0px;
                                    border-radius: 10px;
                                    letter-spacing: 0.3px;
                                    //background: #2e57a9;
                                    color: #2e57a9;
                                    //margin-bottom: 20px;
                                    text-decoration: none;
                                }
                                p{
                                    margin: 0;
                                }
                                .card-title:hover {
                                    //background: #193e88;
                                }
                                .heading {
                                    color: #262626;
                                    text-decoration: none;
                                    h4 {
                                        min-height: 112px;
                                    }
                                    @media (min-width: 768px) and (max-width: 991.98px) {
                                        h4 {
                                            min-height: 53px;
                                        }
                                    }
                                    h4:hover {
                                        color: #0a368d;
                                    }
                                    @media (min-width: 768px) and (max-width: 991.98px) {
                                        h4 {
                                            font-size: 17px;
                                        }
                                    }
                                }
                                
                            }
                            .object-flex {
                                display: flex;
                                justify-content: space-between;
                                padding: 0 10px;
                                padding-bottom: 6px;
                                .object-img {
                                    .media {
                                        img {
                                            width: 35px;
                                            height: 35px;
                                            border-radius: 50%;
                                        }
                                        .media-body {
                                            h6 {
                                                margin-bottom: 3px;
                                                font-weight: 700;
                                            }
                                            p {
                                                font-size: 12px;
                                                color: #919090;
                                                margin-bottom: 0;
                                            }
                                        }
                                    }
                                }
                                .object-icon {
                                    align-self: center;
                                    font-size: 12px;
                                    span {
                                        i {
                                            color: #2e57a9;
                                            font-size: 18px;
                                            cursor: pointer;
                                        }
                                    }
                                    a:first-child {
                                        padding-right: 13px;
                                    }
                                }
                            }
                        }
                        
                    }
                    .load-more {
                        margin-top: 50px;
                        margin-bottom: 50px;
                        a {
                            padding: 15px 30px;
                            text-decoration: none;
                            background: #2e57a9;
                            color: #fff;
                            border-radius: 10px;
                        }
                        a:hover {
                            background: #193e88;
                        }
                    }
                }
            }
            .blog-4 {
                .widget {
                    margin-bottom: 30px;
                    background: white;
                    .widget-search {
                        position: relative;
                        input {
                            color: #0b2154;
                            font-size: 14px;
                            background: #f2f2f2;
                            position: relative;
                            width: 100%;
                            height: 75px;
                            border: 0;
                            padding: 0 40px
                        }
                        input:focus-visible {
                            outline: 0;
                        }
                        @media (min-width: 768px) and (max-width: 991.98px) {
                            input {
                                padding: 0 15px;
                            }
                        }
                        span {
                            color: #193e88;
                            font-size: 16px;
                            position: absolute;
                            right: 40px;
                            top: 50%;
                            transform: translateY(-50%);
                            i {
                                cursor: pointer;
                            }
                        }
                    }
                    .bs-widget-content {
                        border: 2px solid #eaeaea;
                        padding: 45px 30px 30px 30px;
                        .bs-widget-title {
                            font-size: 24px;
                            position: relative;
                            padding-bottom: 20px;
                            margin-bottom: 25px;
                            font-weight: 700;
                            &::before {
                                content: "";
                                position: absolute;
                                background: #193e88;
                                width: 35px;
                                height: 3px;
                                left: 0;
                                bottom: 0;
                                z-index: 1;
                            }
                        }
                        ul {
                            margin: 0px;
                            padding: 0px;
                            li {
                                list-style: none;
                                a {
                                    color: #262626;
                                    font-size: 16px;
                                    font-weight: 500;
                                    display: block;
                                    background: #ffffff;
                                    margin-bottom: 10px;
                                    padding: 2px 0px 2px 0px;
                                    position: relative;
                                    z-index: 1;
                                    transition: 0.3s;
                                    text-decoration: none;
                                    &:hover {
                                        color: #193e88;
                                    }
                                    span {
                                        float: right;
                                        i {
                                            color: #193e88;
                                            font-size: 18px;
                                        }
                                        @media (min-width: 768px) and (max-width: 991.98px) {
                                            i {
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                }
                                @media (min-width: 768px) and (max-width: 991.98px) {
                                    a {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                        .object-flex {
                                img {
                                    width: 100px;
                                    height: 70px;
                                    object-fit: cover;
                                }
                                @media (min-width: 768px) and (max-width: 991.98px) {
                                    img {
                                        width: 70px;
                                        height: 70px;
                                    }
                                }
                                .object-body {
                                h6 {
                                    font-size: 14px;
                                    font-weight: 700;
                                    &:hover {
                                        color: #193e88;
                                    }
                                }   
                                @media (min-width: 768px) and (max-width: 991.98px) {
                                    h6 {
                                        font-size: 10px;
                                    }
                                }
                                p {
                                    margin-bottom: 0;
                                    font-size: 14px;
                                    color: #596277;
                                }
                                @media (min-width: 768px) and (max-width: 991.98px) {
                                    p {
                                        font-size: 9px;
                                    }
                                }
                            }
                        }
                        .cloudtag {
                            p {
                                color: #262626;
                                font-size: 15px;
                                font-weight: 500;
                                line-height: 1;
                                background: #f2f2f2;
                                display: inline-block;
                                padding: 10px 16px;
                                margin: 4px 3px;
                                text-decoration: none;
                                -webkit-transition: all 0.3s ease-out 0s;
                                -moz-transition: all 0.3s ease-out 0s;
                                -ms-transition: all 0.3s ease-out 0s;
                                -o-transition: all 0.3s ease-out 0s;
                                transition: all 0.3s ease-out 0s;
                                &:hover {
                                    color: #ffffff;
                                    background: #193e88;
                                }
                            }
                        }   
                        .instagram {
                            display: flex;
                            flex-wrap: wrap;
                            flex-basis: 0 0 33%;
                            li {
                                flex: 0 0 33%;
                                max-width: 33%;
                                padding: 5px 5px 1px 5px;
                                .insta {
                                    position: relative;
                                    display: block;
                                    margin-bottom: 0;
                                    &::before {
                                        content: "";
                                        position: absolute;
                                        background-image: -moz-linear-gradient(0deg, rgba(216, 19, 36, 0.90196) 0%, rgba(150, 24, 52, 0.9) 32%, rgba(11, 33, 84, 0.9) 99%, #0b2154 100%);
                                        background-image: -webkit-linear-gradient(
                                    0deg
                                    , rgba(216, 19, 36, 0.90196) 0%, rgba(150, 24, 52, 0.9) 32%, rgba(11, 33, 84, 0.9) 99%, #0b2154 100%);
                                        background-image: -ms-linear-gradient(0deg, rgba(216, 19, 36, 0.90196) 0%, rgba(150, 24, 52, 0.9) 32%, rgba(11, 33, 84, 0.9) 99%, #0b2154 100%);
                                        width: 100%;
                                        height: 100%;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        top: 0;
                                        z-index: 1;
                                        -webkit-transition: all 0.3s ease-out 0s;
                                        -moz-transition: all 0.3s ease-out 0s;
                                        -ms-transition: all 0.3s ease-out 0s;
                                        -o-transition: all 0.3s ease-out 0s;
                                        transition: all 0.3s ease-out 0s;
                                        transform: scale(0);
                                    }
                                    img {
                                        width: 100%;
                                    }
                                    span {
                                        position: absolute;
                                        left: 0;
                                        right: 0;
                                        top: 50%;
                                        z-index: 2;
                                        text-align: center;
                                        transform: translateY(-50%) scale(0);
                                        -webkit-transition: all 0.3s ease-out 0s;
                                        -moz-transition: all 0.3s ease-out 0s;
                                        -ms-transition: all 0.3s ease-out 0s;
                                        -o-transition: all 0.3s ease-out 0s;
                                        transition: all 0.3s ease-out 0s;
                                        i {
                                            color: #ffffff;
                                            font-size: 30px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    @media (min-width: 768px) and (max-width: 991.98px) {
                        .bs-widget-content {
                            padding: 25px 15px;
                        }
                    }
                    @media (max-width: 575.98px) {
                        .bs-widget-content {
                            padding: 45px 15px 30px 15px;
                        }
                    }
                }
                .widget-area {
                    .heading {
                        border-bottom: 2px solid #dddddda6;
                        margin-bottom: 25px;
                        padding-bottom: 3px;
                        h6 {
                            font-size: 17px;
                        }
                    }
                    .heading::after {
                        content: "";
                        width: 55px;
                        height: 2px;
                        position: absolute;
                        background: #0a368d;
                        top: 289px;
                        left: 15px;
                    }
                    .sidebar-widget {
                        .sidebar-media {
                            img {
                                width: 112px;
                                height: 112px;
                                border-radius: 15px;
                            }
                            .sidebar-body {
                                a {
                                    text-decoration: none;
                                    color: #262626;
                                    h6 {
                                        line-height: 25px;
                                        font-size: 18px;
                                    }
                                    h6:hover {
                                        color: #0a368d;
                                    }
                                    @media (min-width: 992px) and (max-width: 1199.98px) {
                                        h6 {
                                            font-size: 16px;
                                        }
                                    }
                                    @media (min-width: 768px) and (max-width: 991.98px) {
                                        h6 {
                                            font-size: 10px;
                                            line-height: 17px;
                                        }
                                    }
                                }
                                ul {
                                    padding-left: 15px;
                                    li {
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #919090;
                                    }
                                    @media (min-width: 768px) and (max-width: 991.98px) {
                                        li {
                                            font-size: 7px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }
    }
}