.product_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.Hard_products {
  .row {
    flex-direction: column;
  }
}
.product {
  display: inline-block;
  padding: 5px;
  box-shadow: 0px 0px 2px #aaaaaa;
  border-radius: 5px;
  text-align: center;
  max-width: 200px;
  margin-bottom: 20px;
  margin-right: 15px;
  padding-top: 10px;
  width: 100%;
  height: 330px;
  a {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    &:hover {
      text-decoration: none;
      img {
        -webkit-transform: scale(1.08) !important;
        transform: scale(1.08) !important;
        z-index: 999;
      }
    }
    img {
      max-width: 150px;
      height: 150px;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
      object-fit: contain;
    }
  }
}
.product_body {
  padding: 10px;
  > p {
    display: flex;
    flex-direction: column;
    margin: 0;
    text-align: left;
    a {
      color: inherit;
      font-weight: 600;
      text-decoration: none;
    }
    span {
      font-size: 12px;
    }
  }
}
.product_icon {
  span {
    cursor: pointer;
    font-size: 20px;
    padding: 0 10px;
    box-shadow: 1px 2px 5px #aaa;
    border-radius: 4px;
    transition: 0.3s ease-out;
    &:hover {
      background: #aaa;
    }
  }
  .wishlist {
    &:hover {
      svg {
        color: #ef0000;
        fill: #ef0000;
        color: #4EA699;
        fill: #4EA699 ;
      }
    }
  }
  .shop {
    &:hover {
      svg {
        color: #ef0000;
        fill: #ef0000;
        color: #4EA699;
        fill: #4EA699;
      }
    }
  }
  display: flex;
  justify-content: center;
  margin-top: 10px;
  span.wishlist {
    margin-right: 15px;
  }
}
.product.last_child {
  height: auto;
  vertical-align: top;
  position: relative;
  min-height: 282px;
  display: flex;
  justify-content: center;
  .product_body {
    top: 50%;
    position: absolute;
    transform: translateY(-50%);

    .button {
      display: block;
      color: #fff !important;
      &:hover{
        color: #444540!important;
      }
    }
  }
}
@media (max-width: 767px) {
  .product {
    margin-right: 0;
    max-width: 240px;
  }
}
@media (max-width: 575px) {
  .product {
    a {
      img {
        max-width: 90%;
      }
    }
  }
  .product.last_child {
    width: 100%;
    min-height: 165px;
  }
  .product_wrapper {
    justify-content: flex-start;
  }
}
@media (max-width: 539px) {
  .product_wrapper {
    justify-content: flex-start;
  }
}
@media (max-width: 369px) {
  .product.last_child {
    .product_body {
      position: relative;
    }
  }
}

.cart a.outofstock:after {
  content: "Out of Stock";
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  background-color: #343a4042;
  color: red;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  opacity: 0.75;

  @media (max-width: 575px) {
    font-size: 20px;
  }
}

a.outofstock {
  position: relative;
}

.product_icon span.disabled {
  opacity: 0.5;
}

.product_icon span.disabled:hover svg {
  fill: rgb(27, 27, 27);

}

a.outofstock:after {
  content: "Out of Stock";
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  background-color: #343a4042;
  color: red;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  opacity: 0.75;
  text-align: center;
  @media (max-width: 575px) {
    font-size: 20px;
  }
}

a.outofstock {
  display: inline-block;
  width: 100%;
  position: relative;
}

.mobile_img {
  display: none;
}
/////////////////////////////////////////// Mobile view /////////////////////
@media (max-width: 575px) {
  .product_wrapper {
    .item {
      width: 100%;
    }
    .product {
      max-width: 100%;
      display: flex;
      height: 165px;
      padding-bottom: 10px;
      align-items: center;
      > a {
        display: none;
      }
      .mobile_img {
        display: block;
        width: 100%;
        max-width: 120px;
        height: auto;
      }
      .product_body {
        padding: 10px 5px;
        width: 100%;
        .product_icon {
          justify-content: flex-start;
        }
      }
    }
  }
}
