$logoColor: #16438d;
$navbarColor: #000;
$whiteColor: #fff;

.tab-pane {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #ececec;
  border-radius: 0.25rem;
  padding: 25px;

  @media (max-width: 575px) {
    padding: 0;
  }
  .contact-heading {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #f3f4f6;
    border-bottom: 1px solid #f3f4f6;
    font-weight: 600;
    font-size: 16px;
  }
  .contact-row {
    margin: 35px 0;
    .contact-6 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 700;
      align-items: center;
      @media (max-width: 575px) {
        flex-direction: column;
        align-items: flex-start;
      }
      input {
        max-width: 230px;
        width: 100%;
        border: 1px solid #e2e1e1;
        height: 35px;
        border-radius: 4px;
        padding-left: 10px;
        @media (max-width: 575px) {
          max-width: 100%;
        }
      }
    }
  }
}

.my-heading {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f3f4f6;
  border-bottom: 1px solid #f3f4f6;
  font-weight: 600;
  font-size: 18px;
}
.user-account {
  padding: 0px 15px;
  .my-heading {
    font-size: 18px;
  }
  .nav-tabs {
    border-bottom: 0;
    padding: 25px 0px;

    a {
      width: 25%;
      border: 0;
      text-align: center;
      background: $whiteColor;
      color: $navbarColor;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding: 10px 0;
      font-size: 16px;
      font-weight: 600;
    }

    a:hover {
      background-color: $logoColor;
      border-radius: 4px;
      color: $whiteColor;
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
      a {
        font-size: 12px;
      }
    }
    @media (max-width: 575.98px) {
      a {
        font-size: 12px;
      }
    }

    @media (max-width: 575.98px) {
      a {
        width: 48%;
      }
    }

    a.active {
      background: #16438d;
      font-weight: 600;
      color: #fff;
      border-radius: 0;
      margin: 0px 5px;
    }
  }
}

.savedProduct {
  .my-heading {
    font-size: 18px;
    border: 1px solid #dee2e6;
  }
  table {
    font-size: 14px;
    thead {
      border: 1px solid #ddd;
    }
    tbody {
      tr {
        border: 1px solid #ddd;
        td {
          vertical-align: baseline;
          img {
            max-width: 40px;
            max-height: 40px;
          }
        }
      }
    }
  }
}
.listing-row {
  padding: 0 15px;
  .item {
    width: 100%;
    margin-bottom: 15px;

    .item-card {
      background-color: #fff;
      box-shadow: 0 1px 3px #ddd;
      border: 0;
      .item-row {
        .left-3 {
          img {
            padding: 25px;
            height: 210px;
            object-fit: cover;
          }
          span {
            background: #0a368d;
            clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
            padding: 3px 16px;
            font-size: 11px;
            font-weight: 600;
            border-radius: 4px;
            position: absolute;
            top: 15px;
            left: 15px;
            color: #fff;
            text-transform: uppercase;
          }
        }
        .item-6 {
          padding: 0 20px 0px 25px;
          .card-body {
            a {
              color: #383838;
              h6 {
                margin-bottom: 0;
              }
            }
            .review {
              display: flex;
              span.text {
                font-size: 14px;
                align-self: center;
                padding-left: 8px;
                color: #80878e;
              }
              @media (min-width: 992px) and (max-width: 1199.98px) {
                span.text {
                  font-size: 12px;
                }
              }
            }
            .des {
              ul {
                padding: 5px 0 5px 20px;
                li {
                  color: #6c757d;
                  font-size: 14px;
                }
              }
            }
          }
        }
        .right-3 {
          border-left: 1px solid #ebebeb;
          text-align: center;
          padding-top: 20px;
          .closeIcon {
            position: absolute;
            top: 0;
            right: 5px;
            cursor: pointer;
          }
          h5 {
            font-family: sans-serif;
            font-weight: 500;
            span {
              font-size: 20px;
              del {
                font-size: 16px;
                color: #999;
                padding-left: 10px;
              }
            }
          }
          .add {
            margin: 25px 0;
            a {
              padding: 8.5px 24px;
              background: #2e57a9;
              color: #fff;
              font-size: 14px;
              border-radius: 4px;
              text-decoration: none;
              &:hover {
                background: #193e88;
              }
            }
          }
          p {
            font-size: 14px;
            color: #262626;
            margin-bottom: 8px;
            i {
              padding-right: 8px;
              color: #d9d9d9;
            }
            a {
              color: #262626;
            }
          }
          .sku {
            border-top: 1px solid #ebebeb;
            margin-top: 10px;
            p {
              margin-bottom: 0;
              color: #999;
              font-size: 13px;
              padding: 8px 0;
            }
          }
        }
      }
    }
  }
  .pagination {
    border: none;
  }
}

.listing-row .item .item-card .item-row .left-3 span.out-of-stock {
  background: #c32111;
}

.listing-row .item .item-card .item-row .right-3 .add a.Out-of-stock-btn {
  background: #c32111;
  opacity: 0.4;
  cursor: not-allowed;
}
