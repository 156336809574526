.banner_slider {
  padding: 0;

  .owl-carousel {
    position: relative;
    margin: 0;
    .item {
      img {
        height: auto;
        width: 100%;
      }
    }
    .owl-dots {
      margin: 0 !important;
      position: absolute;
      //   bottom: 0;
      width: 100%;

      button {
        &.active {
          span {
            background: #f17a24 !important;
          }
        }
      }
    }
  }
}

.banner {
  .owl-dots {
    margin: 0 !important;
    position: absolute;
    top: 93%;
    width: 100%;
    button.owl-dot {
      span {
        width: 20px;
        height: 20px;
        background: #fff;
      }
    }
    button.owl-dot.active {
      span {
        background: red;
      }
    }
  }
}
@media (max-width: 767px) {
  .banner {
    .owl-dots {
      top: 100%;
      margin-top: -40px !important;
      button.owl-dot {
        span {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

/* //////////////////////////////////////////// Best Selling Products ////////////////////////// */

.best_selling_product {
  padding: 20px 0;
}
.slider_title {
  padding: 20px 20px;
  h3 {
    font-size: 22px;
    text-transform: uppercase;
    padding-top: 20px;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}
.cart {
  display: flex;
  position: relative;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: auto;
  a {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    text-decoration: none;

    &:hover {
      -webkit-transform: scale(1.05) !important;
      transform: scale(1.05) !important;
      z-index: 999;
    }
    img {
      max-width: 185px;
      height: 162px;
      margin: auto;
      object-fit: contain;
    }
  }
}
.cart_body {
  padding: 14px;
  position: relative;
  a {
    color: #000;
    text-decoration: none;
    display: inline-block;

    &:hover {
      text-decoration: none;
    }
  }
  p {
    margin-bottom: 1px;
  }
}
.product_cart {

  .row {
    flex-direction: column;
    background: #fff;

    border-radius: 20px;
  }
  .owl-dots {
    display: none;
  }
  background-color: #eee;
  .owl-item {
    &:last-child {
      .cart {
        border: 0;
      }
    }
  }
  .owl-nav {
    position: absolute;
    top: -70px;
    width: 100%;
    text-align: right !important;
    padding-right: 15px;
    button {
      background: #005195 !important;
      background: #444540!important;
      
      height: 30px;
      span {
        font-size: 35px;
        position: relative;
        top: -15px;
        padding: 0 12px;
        color: #fff;
      }
    }
  }
}
.banner {
  background: #f3f5f9;
}

/* //////////////////////// Best Brands /////////////////////////////// */

.best_brands {
  @media (max-width: 767px) {
    display: none;
  }
  .owl-carousel {
    position: relative;
    padding: 20px 0;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: rgba(99, 110, 114, 0.3);

    .owl-stage-outer {
      .owl-item {
        .img_wrap {
          width: 100%;
          max-width: 150px;
          img {
            height: auto;
            display: inline-block;
            width: 100%;
            height: 60px;
            object-fit: contain;
          }
        }
      }
    }
    .owl-nav {
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 100%;
      top: -8px;
      button {
        span {
          font-size: 45px;
          position: relative;
          top: -7px;
        }
        position: relative;
        &:hover {
          background-color: transparent;
          color: #000;
        }
      }
      button.owl-prev {
        right: 30px;
      }
      button.owl-next {
        left: 30px;
      }
    }
  }
}
.civil_lab_slider {
  .owl-carousel {
    position: relative;
    padding: 20px 0;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: rgba(99, 110, 114, 0.3);
    .owl-nav {
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 100%;
      top: -8px;
    }
  }
}
@media (max-width: 767px) {
  .best_brands {
    .owl-carousel {
      .owl-nav {
        display: none;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .best_brands {
    .owl-carousel {
      .owl-nav {
        button.owl-next {
          left: 15px;
        }
        button.owl-prev {
          right: 15px;
        }
        top: 5px;
      }
    }
  }
}

/* /////////////////////////////// Our Valued Customers ///////////////////////// */

section.value_customer {
  background: #acd8d147;
  background-color: #eee;

  @media (max-width: 767px) {
    display: none;
  }
}
.marquee_wrapper {
  .marquee-container {
    .marquee {
      display: flex;
      justify-content: space-around;
    }
    .overlay {
      &:after {
        display: none;
      }
      &:before {
        display: none;
      }
    }
  }
  .item {
    margin-right: 13px;
    .img_wrap {
      max-width: 145px;
      // height: 55px;
      width: 100%;
      img {
        height: auto;
        display: inline-block;
        width: 100%;
      }
    }
  }
}

/* ///////////////////////////////////// Civil Lab /////////////////// */
.civil_lab_slider {
  .product {
    margin-right: 2px;
    margin-top: 2px;
    box-shadow: 0px 0px 2px #aaaaaa !important;
    border: 1px solid #aaa;
    @media (max-width: 575px) {
      box-shadow: none !important;
    }
    a {
      img {
        max-width: 150px !important;
        margin: auto;
        height: 150px;
        object-fit: contain;
      }
    }
  }
  background: #fff;
  .owl-carousel {
    .owl-nav {
      top: 50%;
      margin: 0;
      transform: translateY(-50%);
      button {
        span {
          padding: 0 8px;
          font-size: 20px;
          color: #fff;
          position: relative;
          top: -2px;
        }
        background: #005195 !important;
      }
    }
    padding: 0;
  }
}

/* ///////////////////////////////////// Measuring Instrument ////////////////////// */

.measuring_instrument {
  .product {
    margin: 5px 10px;
    max-width: 230px;
    margin-bottom: 15px;

    @media (max-width: 575px) {
      max-width: 85%;
    }
    a {
      img {
        max-width: 170px;

        @media (max-width: 575px) {
          max-width: 90%;
        }
      }
    }
  }
  .product_body {
    > p {
      text-align: center;
      @media (max-width: 575px) {
        text-align: left;
      }
      a {
        text-align: center;

        @media (max-width: 575px) {
          text-align: left;
        }
      }
    }
  }
  .product_icon {
    padding-bottom: 10px;
  }
}
.measuring_instrument.product_cart {
  background: #fff;
}
@media (max-width: 767px) {
  .measuring_instrument {
    .owl-carousel.owl-loaded {
      text-align: center;
    }
  }
}

/* ////////////////////////////// Best Deals on ////////////////////////////// */

.start_card {
  padding: 5px;
  display: flex;
  max-width: 320px;
  .start_card_img {
    a {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
      &:hover {
        text-decoration: none;
        img {
          -webkit-transform: scale(1.08) !important;
          transform: scale(1.08) !important;
          z-index: 999;
          object-fit: contain;
        }
      }
      img {
        max-width: 100px;
        height: auto;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
        transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
        object-fit: contain;
      }
    }
  }
  @media (max-width: 575px) {
    max-width: 100% !important;
  }
}
.start_card_img {
  border: 1px solid #aaa;
}
.start_card_body {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: 575px) {
    width: 100%;
  }
  a {
    color: inherit;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }
  }
  .text_aling {
    justify-content: flex-start;
    position: absolute;
    bottom: 0;

    @media (max-width: 575px) {
      position: static;
    }
  }
}
.best_dealon_wrapp {
  background: #fff;
}
.best_dealon {
  .slider_title {
    @media (max-width: 767px) {
      text-align: center;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .owl-nav {
    position: absolute;
    top: -92px;
    right: 0;
    button {
      span {
        font-size: 40px;
      }
      &:hover {
        background: transparent !important;
        color: inherit !important;
      }
    }
    button.owl-prev {
      margin-right: 10px;
    }
  }
  .slider_title {
    h3 {
      font-size: 20px;
    }
  }
}

/* ////////////////////////////// Best Deals on ////////////////////////////// */

.start_card_body {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  a {
    color: inherit;
    text-decoration: none;
  }
}
.best_dealon_wrapp {
  background: #fff;
}
.best_dealon {
  .start_card {
    padding: 10px;
    display: flex;
    max-width: 320px;
    border: 1px solid rgba(170, 170, 170, 0.56);
    margin-bottom: 20px;
    height: 167px;
    @media (max-width: 767px) {
      margin: auto;
      margin-bottom: 20px;
    }
    .start_card_img {
      border: 0;
      max-width: 100px;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      a {
        position: static;
        img {
          width: 100%;
          height: auto;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }

  .owl-nav {
    position: absolute;
    top: -92px;
    right: 0;
    button {
      span {
        font-size: 40px;
      }
      &:hover {
        background: transparent !important;
        color: inherit !important;
      }
    }
    button.owl-prev {
      margin-right: 10px;
    }
  }
  .slider_title {
    h3 {
      font-size: 20px;
    }
  }
}

/* ////////////////////////// Related Product ///////////////// */

.related_products {
  .container {
    .slider_title {
      width: 100%;
    }
    .row + .row {
      // background: #fff;
      padding: 30px 0 20px;
      // border: 1px solid;
    }
  }
  background: #f3f5f9;
  a {
    text-decoration: none;
  }
  .related_products_card {
    border: 1px solid #aaa;
    height: 400px;
    background: #fff;
    will-change: transform;
    transition: transform 450ms;
    a {
      display:block; 
      img {
        max-width: 90%;
        height: 200px;
        object-fit: contain;
      }
      span {
        position: absolute;
        top: 8px;
        left: 15px;
        padding: 4px 10px;
        background: #ef0000;
        color: #fff;
      }
    }
    .related_products_card_body {
      padding: 13px;
      position: relative;
      a {
       
        h5 {
          font-size: 16px;
          font-weight: 500;
          text-transform: capitalize;
          padding-top: 5px;
          color: #000;
        }
        &:hover {
          color: #005195;
          text-decoration: none;
          h5 {
            color: #005195;
          }
        }
      }
      .review {
        display: flex;
        align-items: center;
        .text {
          padding-left: 10px;
        }
      }
      .price {
        display: flex;
        padding-bottom: 5px;
        del {
          font-size: 16px;
          display: flex;
          align-items: center;
        }
        h6 {
          margin: 0;
          padding-left: 10px;
          color: #ef0000;
          font-weight: 700;
          display: flex;
          align-items: center;
        }
      }
    }
    &:hover {
      box-shadow: 0px 3px 9px #005195 !important;
      transition: transform 150ms;
      transform: translateY(-10px);
    }
  }

  .owl-carousel {
    position: relative;
    .owl-nav {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: space-between;

      @media (max-width: 1024px) {
        display: none;
      }

      button {
        background: #fff !important;
        border: 1px solid #000 !important;
        margin: 0 !important;
        padding: 30px 12px !important;
        position: relative;
        span {
          font-size: 50px;
          position: absolute;
          top: 26px;
          left: 1px;
          right: 0;
          top: 50%;
          width: 100%;
          transform: translateY(-50%);
        }

        &.owl-prev {
          left: -35px;
        }
        &.owl-next {
          right: -35px;
        }
        &:hover {
          color: inherit;
        }
      }
    }
    .owl-dots {
      button {
        &.active {
          span {
            background: #f17a24 !important;
          }
        }
      }
    }
  }
}

.measuring_instrument .product.cm-product {
  max-width: 200px;

  @media (max-width: 767px) {
    max-width: 230px;
  }
  @media (max-width: 575px) {
    max-width: 100%;
    margin-left: 0;
  }
}

.marquee_wrapper .marquee-container .marquee {
  display: flex;
  justify-content: flex-start;
}

.cart a.outofstock:after {
  content: "Out of Stock";
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  background-color: #343a4042;
  color: red;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  opacity: 0.75;
  text-align: center;
}

a.outofstock {
  position: relative;
}

.product_icon span.disabled {
  opacity: 0.5;
}

.product_icon span.disabled:hover svg {
  fill: rgb(27, 27, 27);
}

span.compare {
  margin-left: 15px;
}

section.banner_slider .item {
  position: relative;
}

section.banner_slider .item a {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  cursor: pointer;
  top: 0;
  left: 0;
}
.wishlist-icon {
  cursor: pointer;
  transition: color 0.3s ease;
}

.wishlist-icon.wishlisted {
  color: red;
  cursor: not-allowed; 
}


