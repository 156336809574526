.bulk-item .bulk-content-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.bulk-item .content-col {
  width: 75%;
}

.bulk-item .btn-col {
  width: 25%;
  display: flex;
  flex-direction: column;
}

.bulk-item .bulk-wrap {
  margin-top: 20px;
}

.bulk-item {
  border-top: 1px solid #aaa;
}

.bulk-item ul.content-col > li > small {
  font-weight: bolder;
}

.bulk-item ul.content-col > li > p {
  margin-bottom: 10px;
}

.bulk-wrap {
  margin-top: 10px;
}

.bulk-item:first-child {
  border: none;
  margin-bottom: 10px;
}

.cm-btn {
  outline: none;
  border: none;
  margin: 0 5px;
  padding: 4px 10px;
  border-radius: 2px;
  font-size: 12px;
  box-shadow: 0px 0px 3px #aaa;
  background-color: #fff;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  color: #343a40;
  transition: all 0.2s linear;
}

.cm-btn:not(:last-child) {
  margin-bottom: 10px;
}

.cm-btn:hover {
  text-decoration: none;
  color: #343a40;
}

span.cm-btn.request_status {
  border-radius: 20px;
  padding: 3px 10px;
  width: auto;
  margin-left: 0;
  margin-right: 0;
  min-width: unset;
  font-style: italic;
  box-shadow: none;
  color: #343a40;
  background-color: #ffbe176b;
  position: relative;
  top: -1px;
}

.cm-btn:hover {
  box-shadow: 0px 0px 3px #444540;
  background-color: #444540;
  color: #fff;
}

.cm-btn.cancel-request:hover {
  box-shadow: 0px 0px 3px #fb0700ed;
  background-color: #fb0700ed;
  color: #fff;
}

a.productName {
  text-decoration: none;
  color: #005195;
  font-size: 16px;
  font-weight: 500;
}

a.productName:hover {
  text-decoration: none;
}

.pagination-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}

.pagination-wrap > div {
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
}

.prev-page > svg,
.next-page > svg {
  font-size: 20px;
}

.prev-page > svg > polygon,
.next-page > svg > polygon {
  fill: #005195;
}

.prev-page > span:hover,
.next-page > span:hover {
  color: #005195;
}

span.firstPage.active {
  color: #0a368d;
}
.prev-page {
  background-color: #f3f4f669;
  padding: 5px 13px;
  border-radius: 0px;
}

.next-page {
  background-color: #f3f4f669;
  padding: 5px 13px;
  border-radius: 0px;
}

span.bulk-request-date {
  font-size: 12px;
}

@media (max-width: 540px) {
  .bulk-item .btn-col {
    flex-direction: row;
    width: 100%;
    margin-bottom: 15px;
  }

  .bulk-item .content-col {
    width: 100%;
  }

  .bulk-item .bulk-content-wrap {
    flex-wrap: wrap;
  }

  .cm-btn {
    width: 50%;
  }

  .cm-btn:not(:last-child) {
    margin-bottom: 0;
  }
}

/*  converstion Enquiry */
.BE_conversation_wrap {
    /* border: 1px solid #44454063; */
}

.BE_conversation_wrap .top-section {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 15px;
  padding-top: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #80808054;


}

.BE_conversation_wrap .top-section .center {
  width: calc(80% - 100px);
}

.BE_conversation_wrap .top-section .right {
  width: 20%;
  text-align: right;
}
.BE_conversation_wrap .top-section .left {
  width: 60px;
}

.BE_conversation_wrap .top-section .left img {
  height: 60px;
  width: 60px;
  border-radius: 20px;
  box-shadow: #32325d40 -1px 1px 12px -2px, #0000004d -1px -1px 5px -3px;
}

.BE_conversation_wrap .top-section .center h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
}

.BE_conversation_wrap .top-section .center p {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}
.BE_conversation_wrap .top-section  .right .enquiry-list {
    font-size: 25px;
    color: #4EA699;
    cursor: pointer;
}

.BE_conversation_wrap .top-section  .right .enquiry-list:hover {
    color: #4EA699;
}

.BE_conversation_wrap .messages-section {

    padding: 40px 15px;
    min-height: 400px;
    max-height:700px;
    overflow-x: hidden; 
}

.BE_conversation_wrap .bottom-section{
    padding: 10px 15px;
}
.BE_conversation_wrap .bottom-section .input-div {
    display: flex;
}

.BE_conversation_wrap .bottom-section .input-div input {
    background-color: #80808033;
    margin: 0;
    padding: 5px 8px;
}

.BE_conversation_wrap .bottom-section .input-div button {
    min-width: 78px;
    border: 0;
    padding: 0;
    line-height: 1;
    display: inline-block;
    margin: 0;
}

.BE_conversation_wrap .bottom-section .input-div input:focus {
    outline: none;
}

.BE_conversation_wrap .bottom-section .input-div button:hover {
    background: #444540;
    border: 1.5px solid #444540;
    color: #fff;
}

.BE_conversation_wrap .message-content {
    background-color: #9e9e9e70;
    margin-bottom: 20px;
    padding: 6px 16px;
    border-radius: 20px;
    max-width: 300px;
    position: relative;
    border-bottom-left-radius: 0;
}

.BE_conversation_wrap .message-content.user {
    background-color: #4ea69975;
    margin-right: 0;
    float: right;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 20px;
}

.BE_conversation_wrap .message-content p {
    font-weight: 500;
    margin: 0;
}

.BE_conversation_wrap .message-content span {
    font-size: 10px;
    color: #808080d9;
}



.BE_conversation_wrap .message-content-wrap {
    width: 100%;
}

.BE_conversation_wrap .message-content-wrap:after {
    content: '';
    display: table;
    clear: both;
}

.BE_conversation_wrap .message-content-wrap:after {
    content: '';
    display: table;
    clear: both;
}



