.offline_business {
  .offline_business_content {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 991px) {
      justify-content: space-between;
    }

    .offline_business_card {
      border: 1px solid #aaa;
      max-width: 32.3333%;
      margin: 0 5px;
      margin-top: 15px;

      @media (max-width: 991px) {
        width: 48%;
        max-width: 100%;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
      h6 {
        background-color: #444540;
        padding: 5px;
        color: #fff;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 0;
        text-align: center;
      }
      ul {
        padding: 15px 10px;
        list-style: none;
        margin: 0;
        li {
          margin: 0;
          font-size: 12px;
          margin-bottom: 2px;
          color: #666666;
          letter-spacing: 0.5px;
          .name {
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .common_pages_content {
    padding-left: 0;
    padding-right: 0;
  }
}
