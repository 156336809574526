.contact {
  .contact.col-12 {
    padding: 0;
  }
  .slider_title.contact_title {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 10px;
    h5 {
      text-transform: uppercase;
      font-size: 18px;
      color: #fff;
      background-color: #4EA699;
      padding: 15px 10px;
      margin-bottom: 0;
    }
  }
  .contact_content {
    // box-shadow: 0px 2px 5px #aaa;
    // background: #f3f5f9;
    ul {
      box-shadow: 0px 2px 5px #aaa;
      background: #f3f5f9;
      margin-bottom: 0;
      height: 100%;
      > li {
        // border: 1px solid;
        padding-left: 20px;
        min-height: 100px;
        vertical-align: middle;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0px 0px 1px #005195;
        &:last-child {
          box-shadow: none;
        }

        span {
          &:first-child {
            font-weight: normal;
            font-weight: normal;
            font-size: 15px;
            margin-top: 2px;
          }
          font-size: 16px;
          font-weight: 600;
          svg {
            margin-right: 10px;
          }
        }
        p {
          margin: 0;
          margin-top: 15px;
          a {
            padding: 10px;
            margin-right: 5px;
            border-radius: 3px;
            &:hover {
              background: #2f3640 !important;
              box-shadow: 0px 2px 7px #c8232c !important;
            }
            svg {
              fill: #fff;
              font-size: 16px;
            }
          }
          a.facebook {
            background: #3b5998;
            &:hover {
              svg {
                path {
                  background: #3b5998;
                }
              }
            }
          }
          a.twitter {
            background: #1da1f2;
          }
          a.instagram {
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
            background: #405de6;
            background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
            background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
          }
          a.pint {
            background: #c8232c;
          }
        }
      }
    }
  }
  .getin_touch {
    padding-top: 40px;
  }
  .contact_form {
    box-shadow: 0px 2px 5px #aaa;
    background: #f3f5f9;
    padding: 10px;
    @media (max-width: 991px) {
      margin-top: 20px;
    }
    .form_button {
      text-align: right;
      .btn.btn-primary {
        padding: 10px 35px;
        border: 0;
        border-radius: 0px;
        font-size: 16px;
        margin-top: 20px;
        color: #fff;
        font-weight: 500;
        transition: background-color 0.3s ease-out;
        background: #444540;
        border: 1.5px solid #444540;
        &:hover {
          background: #fff;
          color: #444540;
          border: 1.5px solid #444540;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .slider_title.contact_title {
      h2 {
        font-size: 24px;
      }
    }
  }
}
