.footer .spacing {
  padding-bottom: 0;
}
.footer {
  @media (max-width: 767px) {
    border-top: 1px solid #aaa;
    margin-top: 15px;
  }
  .row {
    padding: 20px 0;
  }
  h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #4EA699;
    color: #444540;


    @media (max-width: 991px) {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .button {
    display: inline-block;
    width: auto;
    margin-top: 5px;
    color: #fff;
    padding: 5px 30px;
  }
}
.footer_logo {
  a {
    color: #666;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      color:#4EA699;

    }
    span {
      display: block;
    }
    img {
      width: 100%;
      max-width: 242px;
    }
  }
}
.about_bellstone {
  p {
    text-align: justify;
  }
}
.footer_menu {
  .nav {
    > a {
      color: #000;
      padding: 5px 8px;
      -webkit-transition: 0.2s ease-out;
      -moz-transition: 0.2s ease-out;
      -o-transition: 0.2s ease-out;
      transition: 0.2s ease-out;
      &:first-child {
        padding-top: 0;
      }
      &:hover {
        color:#4EA699;
        text-decoration: none;
      }
    }
  }
}
.contact_us {
  ul {
    > li {
      padding-bottom: 10px;
      > span {
        display: block;
      }
      a {
        color: #000;
        span {
          padding-right: 5px;
          color: #4EA699;
        }
      }
    }
  }
}
footer {
  .footer_links {
    width: 100%;
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
    ul {
      width: 100%;
      margin: 0;
      padding: 15px 0;
      flex-wrap: wrap;
      list-style: none;
      display: block;
      > li {
        display: inline-block;
        &:first-child {
          font-size: 15px;
          font-weight: 500;
          padding-right: 10px;
          padding-left: 0;
        }
        > a {
          padding: 0 5px;
          color: #000;
          &:hover{
            color: #4EA699 ;
          }
        }
      }
    }
    ul.brands {
      padding-top: 0;
    }
  }
}

.footer_bottom {
  background: #acd8d147;
  background-color: #eee;
  margin-top: 20px;
  @media (max-width: 991px) {
    padding-bottom: 40px;
  }
  .col {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .social_icon {
    ul {
      display: flex;
      margin: 0;
      > li {
        > a {
          padding: 10px;
          background: red;
          font-size: 23px;
          border-radius: 5px;
          > svg {
            fill: #fff;
          }
          &:hover {
            opacity: 0.8;
            background: #fff !important;
          }
        }
        margin-right: 10px;
      }
      > li.twitter {
        > a {
          background: #1da1f2;
          &:hover {
            svg {
              fill: #1da1f2;
            }
          }
        }
      }
      > li.youtube {
        > a {
          background: #ff0000;
          &:hover {
            svg {
              fill: #ff0000;
            }
          }
        }
      }
      > li.facebook {
        > a {
          background: #3b5998;
          &:hover {
            svg {
              fill: #3b5998;
            }
          }
        }
      }
      > li.linkedin {
        > a {
          background: #0077b5;
          &:hover {
            svg {
              fill: #0077b5;
            }
          }
        }
      }
    }
  }
  .copyright {
    p {
      margin: 0;
      vertical-align: middle;
    }
  }
  .pgateway {
    a {
      img {
        width: 100px;
        height: auto;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        &:last-child {
          margin: 0;
        }
      }
      .american_ex {
        height: 36.6px;
      }
    }
  }
}
@media (max-width: 767px) {
  .footer {
    .row {
      .col {
        text-align: center;
      }
    }
  }
  .contact_us {
    text-align: center;
  }
  .footer_menu {
    text-align: center;
  }
  .about_bellstone {
    text-align: center;
  }
  .footer_bottom {
    .col {
      flex-direction: column;
      > div {
        padding: 10px 0;
      }
    }
  }
}

footer.footer.spacing {
  padding-bottom: 0;
}
