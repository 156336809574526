.otp-verify-main-wrap {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #eee;
}

.otp-verify-main-wrap .otp-verify-inner {
    max-width: 500px;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: -1px 1px 10px 0px #44454054;
}

.otp-verify-main-wrap .otp-verify-inner .logo img {
    max-width: 160px;
}

.otp-verify-main-wrap .otp-content h3 {
    margin: 0;
    padding: 0;
    font-weight: 700;
    margin-bottom: 10px;
    color: #343a40;
    font-size: 25px;
}

.otp-verify-main-wrap .otp-content {
    margin-top: 35px;
}

.otp-verify-main-wrap .otp-content p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
    color: #343a40f2;
}

.otp-verify-main-wrap .otp-btn {
    margin-top: 30px;
}

.otp-verify-main-wrap .otp-btn .button {
  border: 1px solid grey;
  transition: all 0.15s linear;
  margin-bottom: 0;
  display: block;
  width: 100%;
  padding: 5px;
  min-height: 40px;
  margin-bottom: 15px;
  font-weight: 700;
  letter-spacing: 1PX;
}

.otp-verify-main-wrap .otp-btn .button:hover {
  background: #fff;
  color: #444540;
  border: 1.5px solid #444540;
}

.otp-verify-main-wrap .otp-form {
    margin-top: 20px;
}

.otp-verify-main-wrap .otp-form input {
  border: 1px solid grey;
  transition: all 0.15s linear;
  margin-bottom: 0;
  display: block;
  width: 100%;
  padding: 5px;
  min-height: 40px;
  margin-bottom: 15px;
}



.otp-verify-main-wrap .otp-form input:focus {
  outline: none;
  border: 1px solid #4EA699;
  box-shadow: 0px 0px 2px 0px #4ea6991c;
}

.otp-verify-main-wrap span.otp-email {
    color: #4EA699;
}

.otp-verify-main-wrap .otp-form.error input {
    border-color: #bf0f0f;
}

small.error-req {color: #bf0f0f;}

@media(max-width:540px){
  .otp-verify-main-wrap {
    padding-left: 20px;
    padding-right: 20px;
}
}

.animate-pop-in{
    -webkit-animation: pop-in 0.4s;
    -moz-animation: pop-in 0.4s;
    -ms-animation: pop-in 0.4s;
}


@-webkit-keyframes pop-in {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
    }
    100% {
      opacity: 1;
      -webkit-transform: scale(1);
    }
  }
  @-moz-keyframes pop-in {
    0% {
      opacity: 0;
      -moz-transform: scale(0.5);
    }
    100% {
      opacity: 1;
      -moz-transform: scale(1);
    }
  }
  @keyframes pop-in {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  