.newsletter {
  background-color: #eee;
  display: flex;
  align-items: center;
  .col-md-4 {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  .newsletter_img {
    padding-right: 20px;
    align-items: center;
    width: 40%;

    @media (max-width: 767px) {
      width: 100%;
      text-align: center;
      padding-right: 0;
    }
    svg {
      width: 100px;
      min-height: 100px;
      fill: #444540;
      @media (max-width: 767px) {
        width: 75px;
        height: 75px;
      }
    }
  }
  .newsletter_inner {
    width: 80%;

    @media (max-width: 767px) {
      width: 100%;
    }
  }
  form.newsletter_form {
    width: 100%;
    input {
      width: 80%;
      min-height: 50px;
      padding: 5px 10px;
      border: 1px solid #aaa;
      background-color: #fff;

      @media (max-width: 767px) {
        width: 100%;
      }
      &:focus-visible {
        outline: none;
      }
      &:focus {
        border: 1px solid #444540;
        border-right: 0;
      }
    }
    button {
      width: 15%;
      border: 0;
      background: #444540;
      padding: 8px 5px;
      transition: 0.3s ease-out;
      margin-bottom: 10px;
      svg {
        width: 34px;
        height: 34px;
        fill: #fff;
      }
      &:hover {
        > svg {
          fill: #FFF;
        }
      }
    }
  }
  .form_group {
    display: flex;
    align-items: center;
  }
  .newsletter_content {
    h4 {
      font-size: 20px;
      font-weight: 500;
      text-transform: capitalize;
      margin: 0;
      font-size: 20px;
      font-weight: 500;
      text-transform: capitalize;
      margin: 0;
    }
    p {
      margin-bottom: 5px;
      margin-bottom: 5px;
    }
  }
  @media (min-width: 576px) {
    .newsletter {
      .col-md-4 {
        width: 25%;
      }
      .col-md-8 {
        width: 70%;
      }
    }
  }
}
