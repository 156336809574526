// .main {
//     margin: 50px 0 !important;
//     padding: 0 30px;
//     .dashboard-right {
//         border: 1px solid #a7a3a359;
//         padding: 25px;
//         box-shadow: 0 0 10px #a7a3a359;
//     }
// }

// @media (min-width: 768px) and (max-width: 991.98px) {
//     .sidbar .side-navigation-panel-select-option {
//         font-size: 12px;
//         padding: 15px 10px !important;
//     }
// }

.main {
    margin: 50px 0 !important;
    padding: 0 30px;
    .sidbar {
        nav {
            .side-navigation-panel-select-option:hover {
              border-color: rgba(22, 67, 141,var(--tw-border-opacity)) !important;
            }
            .side-navigation-panel-select-option-selected {
                border-color: rgba(22, 67, 141,var(--tw-border-opacity)) !important;
            }
          }
    }
    .dashboard-right {
        border: 0px solid #a7a3a359;
        padding: 25px;
        box-shadow: 0 0 10px #a7a3a359;
        .user-dashboard {            
            font-weight: 600;
            margin-bottom: 15px;
        }
        .userDetail-body {
            color: #fff;
            p {
                text-transform: capitalize;
                margin-bottom: 5px;
            }
            @media (min-width: 768px) and (max-width: 991.98px) {
                p {
                  font-size: 12px;
                }
            }
            h6 {
                margin-bottom: 10px;
            }
            @media (min-width: 768px) and (max-width: 991.98px) {
                h6 {
                    font-size: 12px;
                }
            }
        }
        @media (min-width: 768px) and (max-width: 991.98px) {
            .userDetail-body {
              padding: 10px;
            }
        }
        @media (max-width: 575.98px) { 
            .userDetail-4 {
                margin-bottom: 15px;
            }
        }
    }
    @media (max-width: 575.98px) { 
        .dashboard-right {
            padding: 15px 10px;
            margin: 35px 10px 0 10px;
        }
    }
}
@media (max-width: 575.98px) { 
    .main {
        padding: 0;
    }
}




